const BUTTON_SELECTOR = '[data-enhanced-button]'

const getCursorPos = (mouseEvt) => {
  return {
    x: Math.round(event.offsetX / mouseEvt.target.clientWidth * 100),
    y: Math.round(event.offsetY / mouseEvt.target.clientHeight * 100)
  }
}

const setOverlayPos = (overlay, pos) => {
  overlay.style.top = `${pos.y}%`
  overlay.style.left = `${pos.x}%`
}

const handleMouseEvent = event => {
  const pos = getCursorPos(event)
  const overlay = event.target.querySelector('.block-button__overlay')
  setOverlayPos(overlay, pos)
}

const EnhancedButton = (options = {}) => {
  const settings = Object.assign({
    selector: BUTTON_SELECTOR
  }, options)
  const buttons = document.querySelectorAll(settings.selector)
  if (!buttons) {
    return
  }
  Array.from(buttons).forEach(button => {
    button.addEventListener('mouseenter', handleMouseEvent)
    button.addEventListener('mouseleave', handleMouseEvent)
  })
}

export default EnhancedButton
