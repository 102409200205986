import 'intersection-observer'
import * as Sentry from '@sentry/vue'

// Import external libraries
import lazySizes from 'lazysizes'
// lazyload config settings
window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.loadMode = 1
window.lazySizesConfig.init = false;
import '../../node_modules/lazysizes/plugins/parent-fit/ls.parent-fit'
import VeeValidate from 'vee-validate'
import objectFitImages from 'object-fit-images'

// Import components
import ParallaxRellax from './components/ParallaxRellax'
import EnhancedButton from './components/EnhancedButton'
import ExplosionMove from './components/ExplosionMove'
import { handleContactSubmit, handleContactError } from './components/ContactFormHandler'
import MarqueeInfinite from './components/MarqueeInfinite'
import ShowFooterMap from './components/ShowFooterMap'
import VideoController from './components/VideoController'
import CookieBar from "./components/CookieBar"
import LazyloadUnveil from "./components/LazyloadUnveil"
import MewsPlugin from "./plugins/mews"

// App main
const main = async () => {
  // Async load the Vue and Store module
  const s = await import(/* webpackChunkName: "store" */ './store')
  const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue')

  Vue.use(VeeValidate, { useConstraintAttrs: false })
  Vue.use(MewsPlugin)

  const isProduction = process.env.NODE_ENV === 'production'
  if (isProduction) {
    Sentry.init({
      Vue,
      dsn: 'https://348f4f074163489cb333eff35beebe72@sentry.yournext.agency/34'
    })
  }

  // Auto-discover Vue components
  // const files = require.context('./components', true, /\.vue$/i)
  // files.keys().map(key => Vue.default.component(key.split('/').pop().split('.')[0], files(key).default)

  // Create our vue instance
  const vm = new Vue({
    el: '#app',
    delimiters: ['${', '}'],
    components: {
      'AgeChecker': () => import(/* webpackChunkName: "vue-component-age-checker" */ './components/AgeChecker.vue'),
      'BackgroundHover': () => import(/* webpackChunkName: "vue-component-background-hover" */ './components/BackgroundHover.vue'),
      'BookTableTool': () => import(/* webpackChunkName: "vue-component-book-table-tool" */ './components/BookTableTool.vue'),
      'BookTableToolMobile': () => import(/* webpackChunkName: "vue-component-book-table-tool-mobile" */ './components/BookTableToolMobile.vue'),
      'BookTool': () => import(/* webpackChunkName: "vue-component-book-tool" */ './components/BookTool.vue'),
      'BookToolMobile': () => import(/* webpackChunkName: "vue-component-book-tool-mobile" */ './components/BookToolMobile.vue'),
      'BookVoucherToolMobile': () => import(/* webpackChunkName: "vue-component-book-voucher-tool-mobile" */ './components/BookVoucherToolMobile.vue'),
      'ButtonCircleRotating': () => import(/* webpackChunkName: "vue-component-button-circle-rotating" */ './components/ButtonCircleRotating.vue'),
      'CartCounter': () => import(/* webpackChunkName: "vue-component-cart-counter" */ './components/CartCounter.vue'),
      'CartItemQty': () => import(/* webpackChunkName: "vue-component-cart-item-qty" */ './components/CartItemQty.vue'),
      'CartSummary': () => import(/* webpackChunkName: "vue-component-cart-summary" */ './components/CartSummary.vue'),
      'DatePicker': () => import(/* webpackChunkName: "vue-component-date-picker" */ './components/DatePicker.vue'),
      'Dropdown': () => import(/* webpackChunkName: "vue-component-dropdown" */ './components/Dropdown.vue'),
      'FaqAccordion': () => import(/* webpackChunkName: "vue-component-faq-accordion" */ './components/FaqAccordion.vue'),
      'GuestPicker': () => import(/* webpackChunkName: "vue-component-quest-picker" */ './components/GuestPicker.vue'),
      'GalleryCarousel': () => import(/* webpackChunkName: "vue-component-gallery-carousel" */ './components/GalleryCarousel.vue'),
      'HighlightedListCarousel': () => import(/* webpackChunkName: "vue-component-highlighted-list-carousel" */ './components/HighlightedListCarousel.vue'),
      'ImageZoom': () => import(/* webpackChunkName: "vue-component-image-zoom" */ './components/ImageZoom.vue'),
      'LocationNav': () => import(/* webpackChunkName: "vue-component-location-nav" */ './components/LocationNav.vue'),
      'LocationPickerRestaurant': () => import(/* webpackChunkName: "vue-component-location-picker-restaurant" */ './components/LocationPickerRestaurant.vue'),
      // 'LocationPickerRestaurantMobile': () => import(/* webpackChunkName: "vue-component-location-picker-restaurant-mobile" */ './components/LocationPickerRestaurantMobile.vue'),
      'Logo': () => import(/* webpackChunkName: "vue-component-logo" */ './components/Logo.vue'),
      'Marquee': () => import(/* webpackChunkName: "vue-component-marquee" */ './components/Marquee.vue'),
      'MobileMenu': () => import(/* webpackChunkName: "vue-component-mobile-menu" */ './components/MobileMenu.vue'),
      'NumberInput': () => import(/* webpackChunkName: "vue-component-number-input" */ './components/NumberInput.vue'),
      'PageNav': () => import(/* webpackChunkName: "vue-component-page-nav" */ './components/PageNav.vue'),
      'PaymentMethodSelector': () => import(/* webpackChunkName: "vue-component-payment-method-selector" */ './components/PaymentMethodSelector.vue'),
      'PhotoStack': () => import(/* webpackChunkName: "vue-component-photo-stack" */ './components/PhotoStack.vue'),
      'ProductItemQty': () => import(/* webpackChunkName: "vue-component-product-item-qty" */ './components/ProductItemQty.vue'),
      'ProductsOverview': () => import(/* webpackChunkName: "vue-component-products-overview" */ './components/ProductsOverview.vue'),
      'ProductsOverviewCard': () => import(/* webpackChunkName: "vue-component-products-overview-card" */ './components/ProductsOverviewCard.vue'),
      'RadioList': () => import(/* webpackChunkName: "vue-component-radio-list" */ './components/RadioList.vue'),
      'RadioListMobile': () => import(/* webpackChunkName: "vue-component-radio-list-mobile" */ './components/RadioListMobile.vue'),
      'RadioListRestaurants': () => import(/* webpackChunkName: "vue-component-radio-list-restaurants" */ './components/RadioListRestaurants.vue'),
      'RadioListRestaurantsMobile': () => import(/* webpackChunkName: "vue-component-radio-list-restaurants-mobile" */ './components/RadioListRestaurantsMobile.vue'),
      'RoomCarousel': () => import(/* webpackChunkName: "vue-component-room-carousel" */ './components/RoomCarousel.vue'),
      'SearchNav': () => import(/* webpackChunkName: "vue-component-search-nav" */ './components/SearchNav.vue'),
      'ShowMore': () => import(/* webpackChunkName: "vue-component-show-more" */ './components/ShowMore.vue'),
      'SideCart': () => import(/* webpackChunkName: "vue-component-side-cart" */ './components/SideCart.vue'),
      'SideCartAddToBagButton': () => import(/* webpackChunkName: "vue-component-side-cart-add-to-bag-button" */ './components/SideCartAddToBagButton.vue'),
      'SideCartItem': () => import(/* webpackChunkName: "vue-component-side-cart-item" */ './components/SideCartItem.vue'),
      'SimpleSlider': () => import(/* webpackChunkName: "vue-component-simple-slider" */ './components/SimpleSlider.vue'),
      'TagAutocomplete': () => import(/* webpackChunkName: "vue-component-tag-autocomplete" */ './components/TagAutocomplete.vue'),
      'ViempieField': () => import(/* webpackChunkName: "vue-component-viempie-field" */ './components/ViempieField.vue'),
      'ViempieSelect': () => import(/* webpackChunkName: "vue-component-viempie-select" */ './components/ViempieSelect.vue'),
      'ViempieSelectCountryState': () => import(/* webpackChunkName: "vue-component-viempie-select-country-state" */ './components/ViempieSelectCountryState.vue'),
    },
    data: {
      privateState: {},
      sharedState: s.default.state,
      crossingElement: false
    },
    created: function () {
      s.default.setCartAction(window.Craft)
      s.default.setTokenAction(window.Craft.token)
      s.default.setLocationAction(window.Craft.synxisLocations)
      s.default.setBookingVarsAction()
      s.default.setSiteLangAction(window.Craft.siteLang)
      s.default.setMewsAction(window.Craft.mews)
      s.default.setCommerceStatus(window.Craft.isCommerceInstalled)
    },
    mounted() {
      // init pagePositionDetector
      this.pagePositionDetector()
    },
    methods: {
      toggleActiveOverlay: function (overlay) {
        s.default.setActiveOverlayAction(s.default.state.activeOverlay === overlay ? null : overlay)
      },

      toggleGalleryCarousel: (index) => {
        s.default.toggleCarouselGalleryAction(index)
      },
      // toggleMenuOverlay: () => s.default.toggleMenuOverlayAction(),

      toggleSideCartAction: () => s.default.toggleSideCartAction(),

      addToCart(event) {
        const formEl = event.target
        if (s.debug) console.log("Triggerd addToCart form submit: ", formEl)
        if (s.debug) console.log("h: ", h.default)
        // Post request
        const settings = {
          url: "/",
          data: h.default.getFormData(formEl),
          method: "POST",
          headers: {
            "Content-type": "multipart/form-data",
            "X-Requested-With": "XMLHttpRequest"
          }
        }

        axios(settings)
          .then(response => {
            if (s.debug) console.log("addToCart response: ", response)

            const obj = {}
            obj.cart = response.data.cart
            s.default.setCartAction(obj)

            this.toggleSideCartAction()
          })
          .catch(function (error) {
            if (s.debug) console.log("addToCart error: ", error)
          })
      },

      validateAndSubmit(event) {
        const scope = event.target.getAttribute('data-vv-scope')
        this.errors.clear()
        this.$validator.validateAll(scope).then(result => {
          if (result) {
            if (s.debug) console.log(scope + ' submitted')
            // Disable button
            const submitBtn = event.target.querySelector('button')
            if (submitBtn) {
              submitBtn.disabled = true
            }
            // Submit the form
            event.target.submit()
          } else {
            console.log('check errors')
          }
        })
      },

      validateAndSubmitContactForm(event) {
        const scope = event.target.getAttribute('data-vv-scope')
        const honeyPot = event.target.querySelector('[data-contact-form-vali]')
        this.errors.clear()
        this.$validator.validateAll(scope).then(result => {
          if (result) {
            if (s.debug) console.log(scope + ' submitted')
            // Disable button
            const submitBtn = event.target.querySelector('button')
            if (submitBtn) {
              submitBtn.disabled = true
            }
            // check the value of the hidden honeypot field
            if (honeyPot && honeyPot.value !== "") {
              // trigger handleContactError function
              handleContactError(event)
              return
            }
            // Submit the form
            handleContactSubmit(event, scope)
          } else {
            console.log('check errors')
          }
        })
      },

      validateAndSubmitBookingForm(event) {
        const scope = event.target.getAttribute('data-vv-scope')
        const honeyPot = event.target.querySelector('[data-contact-form-vali]')
        this.errors.clear()
        this.$validator.validateAll(scope).then(result => {
          if (result) {
            if (s.debug) console.log(scope + ' submitted')
            // Disable button
            const submitBtn = event.target.querySelector('button')
            if (submitBtn) {
              submitBtn.disabled = true
            }
            // check the value of the hidden honeypot field
            if (honeyPot && honeyPot.value !== "") {
              // trigger handleContactError function
              handleContactError(event)
              return
            }
            // Submit the form
            handleContactSubmit(event, scope)
          } else {
            console.log('check errors')
          }
        })
      },

      isInElement(buttonEl, targetEls) {
        const fixed_position =
          buttonEl.getBoundingClientRect().top + window.scrollY
        const fixed_height = buttonEl.offsetHeight

        this.crossingElement = false
        for (let i = 0; i < targetEls.length; i++) {
          const el = targetEls[i]
          const toCross_position = el.offsetTop
          const toCross_height = el.offsetHeight

          if (fixed_position + fixed_height < toCross_position) {
          } else if (fixed_position > toCross_position + toCross_height) {
          } else {
            this.crossingElement = true
          }
        }
      },

      pagePositionDetector() {
        this.$nextTick(() => {
          let els = document.querySelectorAll("[data-detect-section]")
          const buttonEl = document.querySelector("[data-detect-button]")
          const _self = this
          if (els.length > 0 && buttonEl) {
            window.addEventListener(
              "scroll",
              function (event) {
                _self.isInElement(buttonEl, els)
              },
              false
            )
          }
        })
      }
    },
    computed: {
      showHeaderModifier: function () {
        return !(this.sharedState.showMenuOverlay || this.sharedState.hasScrolled || this.sharedState.menuItemHover)
      }
    }
  })
}
// Execute async function
main()
  .then(value => {
    function domReady() {
      objectFitImages()
      lazySizes.init()

      const isInViewport = function (elem) {
        const bounding = elem.getBoundingClientRect();
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      };

      const imgEls = document.querySelectorAll('.lazyloading')
      for (let i = 0; i < imgEls.length; i++) {
        if (isInViewport(imgEls[i])) {
          lazySizes.loader.unveil(imgEls[i]);
        } else {
          setTimeout(() => {
            lazySizes.loader.unveil(imgEls[i]);
          }, 200);
        }
      }
    }

    if (document.readyState == 'loading') {
      document.addEventListener('DOMContentLoaded', domReady);
    } else {
      domReady();
    }

    LazyloadUnveil({
      el: ".js-lazyload-unveil"
    })

    CookieBar({
      el: ".js-cookie-bar",
      toggle: ".js-cookie-toggle"
    })

    ParallaxRellax({
      el: '[data-rellax]'
    })

    EnhancedButton()

    ExplosionMove({
      el: '[data-explosion-move]'
    })

    MarqueeInfinite({
      el: '[data-marquee-infinite]'
    })

    ShowFooterMap({
      el: '[data-show-map]',
      toggle: '[data-show-map--toggle]',
      target: '[data-show-map--target]',
    })

    VideoController({
      el: '.js-video',
      source: '.js-video--source',
      soundToggle: '.js-video--toggle-sound'
    })

  })
  .catch(error => {
    console.error("error", error)
  })
