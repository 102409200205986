import h from "../../helpers"
import "./style.css"

let speed = 4;
let initMarqueeAnimation = false

const initMarquee = (els) => {
  const marquee = els
  initMarqueeAnimation = true
  marquee.forEach(function (el) {
    const container = el.querySelector('[data-marquee-inner]');
    const content = el.querySelector('[data-marquee-inner] > *');
    //Get total width
    const elWidth = content.offsetWidth;

    //Duplicate content
    let clone = content.cloneNode(true);
    container.appendChild(clone);

    let progress = 1;
    function loop() {
      if (initMarqueeAnimation) {
        progress = progress - speed;
        if (progress <= elWidth * -1) { progress = 0; }
        container.style.transform = 'translateX(' + progress + 'px)';

        window.requestAnimationFrame(loop);
      }
    }
    loop();
  });
}

const checkViewport = (els) => {
  if (window.matchMedia('(min-width: 60em)').matches) {
    initMarqueeAnimation = false
    speed = 4;
    initMarquee(els);
  } else {
    initMarqueeAnimation = false
    speed = 2;
    initMarquee(els);
  }
}

const MarqueeInfinite = (options) => {
  const els = document.querySelectorAll(options.el)
  if (!els.length) return

  checkViewport(els)

  window.addEventListener('resize', function () {
    h.debounce(checkViewport(els), 20)
  })
}

export default MarqueeInfinite
