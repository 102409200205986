import h from "../../helpers"


let initParallaxAnimation = false
let isMobile = false
const initParallaxEffect = (els) => {

  initParallaxAnimation = true
  let topDistance = window.pageYOffset;
  let scrollPos = topDistance;
  window.onscroll = () => {
    topDistance = window.pageYOffset;
  };

  const scrollPlay = () => {
    if (initParallaxAnimation) {

      for (let i = 0; i < els.length; i++) {
        const el = els[i];
        const parallaxMobile = el.hasAttribute('data-parallax-mobile');
        const elShrink = el.hasAttribute('data-explosion-shrink');
        const elXY = el.hasAttribute('data-move-xy');
        const elFadeIn = el.hasAttribute('data-fade-in');
        let depth = el.getAttribute('data-depth');
        if (isMobile && parallaxMobile) {
          depth = depth / 2
        }
        scrollPos += (topDistance - scrollPos);
        let movement = (scrollPos * depth) * -2;
        let movementY = (scrollPos * depth) * -1;
        let movementX = (scrollPos * depth) * -1;
        let scaleY = (1 - (scrollPos * depth / 1000));
        let scaleX = (1 - (scrollPos * depth / 1000));
        let opacity = (scrollPos * depth) * 0.1
        if (elXY && elShrink) {
          movement = (scrollPos * depth) * -2;
          scaleY = (1 - (scrollPos * (depth * 1.375) / 1000));
          scaleX = (1 - (scrollPos * (depth * 1.375) / 1000));

          if (i === 0) {
            movementY = (scrollPos * depth) * -1;
            movementX = (scrollPos * depth) * 1;
          } else if (i % 2 === 0) {
            movementY = (scrollPos * depth) * 1;
            movementX = (scrollPos * depth) * 2;
          } else {
            movementY = (scrollPos * depth) * -1;
            movementX = (scrollPos * depth) * -1;
          }

        } else if (elXY) {
          if (i === 0) {
            movementY = (scrollPos * depth) * -1;
            movementX = (scrollPos * depth) * 1;
          } else if (i % 2 === 0) {
            movementY = (scrollPos * depth) * 1;
            movementX = (scrollPos * depth) * 2;
          } else {
            movementY = (scrollPos * depth) * -1;
            movementX = (scrollPos * depth) * -1;
          }
          if (elFadeIn) {
            opacity = (0 + (scrollPos / 1000) * 2.5)
          }
        } else {
          movement = (scrollPos * depth) * -1;
        }

        if (elXY && elShrink) {
          let scale3d = `scale3d(${scaleY}, ${scaleX}, 1)`;
          if (scaleY > 0 && scaleX > 0) {
            scale3d = `scale3d(${scaleY}, ${scaleX}, 1)`;
          } else {
            scale3d = `scale3d(${0}, ${0}, 1)`;
          }
          const completeTranslate = `${scale3d} translate3d(0, ${movement}px, 0)`;
          el.style['-webkit-transform'] = completeTranslate;
          el.style['-moz-transform'] = completeTranslate;
          el.style.transform = completeTranslate;
        } else if (elXY) {
          const translate3d = `translate3d(${movementX}px, ${movementY}px, 0)`;
          el.style['-webkit-transform'] = translate3d;
          el.style['-moz-transform'] = translate3d;
          el.style.transform = translate3d;
          if (elFadeIn) {
            el.style.opacity = opacity
          }
        } else {
          const translate3d = `translate3d(0, ${movementY}px, 0)`;
          el.style['-webkit-transform'] = translate3d;
          el.style['-moz-transform'] = translate3d;
          el.style.transform = translate3d;
        }
      }
      window.requestAnimationFrame(scrollPlay);
    }
  }

  window.requestAnimationFrame(scrollPlay);
}

const checkViewport = (els) => {
  if (window.matchMedia('(min-width: 60em)').matches) {
    isMobile = false
    initParallaxAnimation = false
    initParallaxEffect(els)
  } else {
    isMobile = true
    initParallaxAnimation = false
    initParallaxEffect(els)
  }
}

const ExplosionMove = (options) => {
  const els = document.querySelectorAll(options.el)
  if (!els.length) return

  checkViewport(els)

  window.addEventListener('resize', function () {
    h.debounce(checkViewport(els), 20)
  })
}

export default ExplosionMove
