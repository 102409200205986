import anime from "animejs"
import h from "../../helpers"

const toggleElsSound = (video, toggleSoundIconMuted, toggleSoundIconSound) => {
  if (!video.paused) {

    video.muted = !video.muted

    if (video.muted) {
      toggleSoundIconMuted.classList.remove('dn')
      toggleSoundIconSound.classList.add('dn')
    } else {
      toggleSoundIconMuted.classList.add('dn')
      toggleSoundIconSound.classList.remove('dn')
    }
  }
}

const setVideoSource = (video) => {
  if (window.innerWidth < 960) {
    video.src = video.dataset.srcMobile
    video.load()
  } else {
    video.src = video.dataset.srcDesktop
    video.load()
  }

  video.addEventListener('loadeddata', function () {
    if (video.readyState >= 2) {
      try {
        video.play();
      } catch (e) {
        // Ignore playback errors
      }
    }
  });
}

const initToggleSoundListener = (el, video, options) => {
  const toggleSound = el.querySelector(options.soundToggle)
  const toggleSoundIconMuted = toggleSound.querySelector(".js-toggle-sound--muted")
  const toggleSoundIconSound = toggleSound.querySelector(".js-toggle-sound--sound")
  toggleSound.addEventListener('click', function () {
    toggleElsSound(video, toggleSoundIconMuted, toggleSoundIconSound)
  })
}

const initScrollListener = (el, video) => {
  const adjustVideo = (el, video) => {
    try {
      if (window.scrollY > 100) {
        video.pause()
      } else if (window.scrollY < 100) {
        video.play()
      }
    } catch (e) {
      // Ignore playback errors
    }
    el.style.opacity = 1 - window.scrollY / el.clientHeight * 3;
  }

  window.addEventListener("scroll", function () {
    h.debounce(adjustVideo(el, video), 20)
  })
}

const VideoController = (options) => {
  const el = document.querySelector(options.el)
  if (!el) return

  const video = el.querySelector(options.source)

  setVideoSource(video)

  initToggleSoundListener(el, video, options)

  initScrollListener(el, video)

}


export default VideoController
