const LazyloadUnveil = (options) => {
  const imgEls = document.querySelectorAll(".js-lazyload-unveil")
  if (!imgEls.length) return

  let imgEl = imgEls[0]
  if (window.matchMedia('(min-width: 60em)').matches) {
    imgEl = imgEls[1]
  } else {
    imgEl = imgEls[0]
  }

  window.lazySizes.loader.unveil(imgEl);
}

export default LazyloadUnveil
