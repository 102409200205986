import h from "./helpers.js"

const store = {
  debug: false,
  state: {
    hasModal: false,
    activeOverlay: null,
    activeHotel: null,
    activeScriptId: null,
    booking: {
      arrive: null,
      depart: null,
      adults: 2,
      children: 0,
      child: 0,

    },
    bookingRestaurant: {
      restaurant: null,
      url: null,
      formitableId: null,
      formitableColorCode: null,
      init: false,
    },
    errorBookingHotel: false,
    galleryCarouselIndex: 0,
    hasScrolled: false,
    menuItemHover: false,
    showGalleryCarousel: false,
    showMenuOverlay: false,
    showSideCart: false,
    sameBillingAddress: true,
    cart: null,
    token: null,
    keyIndex: 0,
    siteLang: '',
    mews: {
      hotelVFiz: {
        key: '',
        city: ''
      },
      hotelVFre: {
        key: '',
        city: ''
      },
      hotelVNes: {
        key: '',
        city: ''
      }
    },
    isCommerceInstalled: false
  },
  static: {
    theme: {
      fizeaustraat: {
        colors: {
          dark: '#241C0F',
          light: '#FFF5E4',
          color1: '#415E23',
          color2: '#E1A724'
        }
      },
      frederiksplein: {
        colors: {
          dark: '#241C0F',
          light: '#FDF9F2',
          color1: '#241C0F',
          color2: '#E4CC3A'
        }
      },
      nesplein: {
        colors: {
          dark: '#241C0F',
          light: '#FDF9F2',
          color1: '#A7600C',
          color2: '#FFC423'
        }
      },
      portal: {
        colors: {
          dark: '#291D0F',
          light: '#F7F4E6',
          color1: '#F7F4E6',
          color2: '#963A1D'
        }
      },
      shop: {
        colors: {
          dark: '#291D0F',
          light: '#F7F4E6',
          color1: '#F7F4E6',
          color2: '#963A1D'
        }
      }
    },
    synxisLocations: {}
  },

  getLocation(hotel) {
    if (this.debug) console.log("getLocation method triggered", hotel)
    let gotMatch = false
    for (let i = 0; i < this.static.synxisLocations.length; i++) {
      if (this.static.synxisLocations[i].hotel == hotel) {
        if (this.debug) console.log("this.static.synxisLocations[hotel]", this.static.synxisLocations[i].hotel)
        gotMatch = true
        return this.static.synxisLocations[i].locationName
      }

    }
    if (!gotMatch) return null
  },

  toggleModalAction() {
    if (this.debug) console.log("toggleModalAction method triggered")
    this.state.hasModal = !this.state.hasModal
  },

  toggleRestaurantReservationModal(restaurantId) {
    window.FT.parse(function () {
      window.FT.widgets.get(`b2_${restaurantId}`).open()
      document.querySelector("body").click()
    })
  },

  toggleFormitableModal(restaurantId, modalType) {
    const _self = this
    window.FT.parse(function (restaurantId, modalType) {
      if (!_self.state.bookingRestaurant.init) {
        if (modalType === 'voucher') {
          window.FT.widgets.get(`b2_${restaurantId}`).openShop('voucher')
          _self.state.bookingRestaurant.init = true
        } else {
          window.FT.widgets.get(`b2_${restaurantId}`).open()
          _self.state.bookingRestaurant.init = true
        }
      }

      document.querySelector("body").click()

      window.addEventListener("ft-widget-close", function () {
        console.log("close");
        sessionStorage.setItem('ft-widget-state', 'close');
        _self.state.bookingRestaurant.init = false
      })
      window.addEventListener("ft-widget-open", function () {
        console.log("open");
        _self.state.bookingRestaurant.init = true
      })

      window.addEventListener("ft-widget-ready", function () {
        console.log("ready");
        // sessionStorage.setItem('ft-widget-state', 'close');
      })

    })
  },

  setRestaurantReservationModal(id, colorCode, modalType = 'reservation') {
    if (this.debug) console.log("toggleRestaurantReservationModal method triggered")
    this.state.bookingRestaurant.formitableId = id
    this.state.bookingRestaurant.formitableColorCode = colorCode
    if (this.debug) console.log("this.state.bookingRestaurant values", this.state.bookingRestaurant.formitableId, this.state.bookingRestaurant.formitableColorCode);

    const restaurantId = this.state.bookingRestaurant.formitableId
    const restaurantColor = this.state.bookingRestaurant.formitableColorCode

    const widgetEl = document.querySelector(".ft-widget-b2")
    widgetEl.dataset.restaurant = restaurantId
    widgetEl.dataset.color = restaurantColor

    // window.FT.parse(function () {
    //   window.FT.widgets.get(`b2_${restaurantId}`).open()
    //   document.querySelector("body").click()
    // })

    // this.toggleFormitableModal(restaurantId, modalType)

    this.toggleRestaurantReservationModal(restaurantId)
  },

  setActiveOverlayAction(overlay) {
    if (this.debug) console.log("setActiveOverlayAction method set: ", overlay)
    this.state.activeOverlay = overlay
  },

  setCartAction(object) {
    if (this.debug) console.log('setCartAction triggered with', object)
    this.state.cart = object.cart
  },

  setLocationAction(object) {
    if (this.debug) console.log('setLocationAction triggered with', object)
    this.static.synxisLocations = object
    if (this.debug) console.log(' this.static.locations', this.static.synxisLocations)
  },

  setTokenAction(token) {
    if (this.debug) console.log('setTokenAction triggered with', token)
    this.state.token = token
  },

  setSiteLangAction(lang) {
    if (this.debug) console.log('setSiteLangAction triggered with', lang)
    this.state.siteLang = lang
  },

  setMewsAction(mews) {
    if (this.debug) console.log('setMewsAction triggered with', mews)
    this.state.mews = mews
  },

  setBookingScriptSettings(hotel = null) {
    if (this.debug) console.log("setBookingScriptSettings", hotel);

    if (hotel != null) {
      for (let i = 0; i < this.static.synxisLocations.length; i++) {
        if (this.static.synxisLocations[i].hotel == hotel) {
          if (this.state.activeScriptId != null) {
            h.removeScriptFile(`${this.state.activeScriptId}-js`)
            h.removeScriptFile(`${this.state.activeScriptId}-stylesheet`)
            h.removeScriptFile(`booking-layout`)
          }

          BOOKING_SETTINGS = {
            "hotel": this.static.synxisLocations[i].hotel,
            "theme": this.static.synxisLocations[i].theme,
            "lang": this.static.synxisLocations[i].lang,
            "emergency": {
              "email": this.static.synxisLocations[i].emergencyEmail,
              "phone": this.static.synxisLocations[i].emergencyPhone
            }
          };

          this.state.activeScriptId = this.static.synxisLocations[i].hotel
          h.addScriptFile(this.state.activeScriptId, this.static.synxisLocations[i].jsScriptUrl)
          h.addLinkFile(this.state.activeScriptId, this.static.synxisLocations[i].cssScriptUrl, "stylesheet", "text/css")
          h.addCustomDomElement("booking-layout", "booking-layout")

        }
      }
    }


  },

  setBookingVarsAction() {
    if (this.debug) console.log(`setBookingVarsAction triggered`)

    for (let i = 0; i < this.static.synxisLocations.length; i++) {
      if (this.static.synxisLocations[i].portalActive) {
        this.state.activeHotel = null
      } else if (this.static.synxisLocations[i].locationActive) {
        this.state.activeHotel = this.static.synxisLocations[i].hotel
      }
    }
    if (this.debug) console.log(`setBookingVarsAction set with:`, this.state.activeHotel)
  },

  toggleMenuOverlayAction() {
    if (this.debug) console.log('toggleMenuOverlayAction triggered')
    this.state.showMenuOverlay = !this.state.showMenuOverlay
  },

  toggleSideCartAction() {
    if (this.debug) console.log('toggleSideCartShowAction triggered')
    this.state.showSideCart = !this.state.showSideCart
  },

  toggleCarouselGalleryAction(index) {
    if (this.debug) console.log('toggleCarouselGalleryAction triggered', index)
    this.state.galleryCarouselIndex = index
    this.state.showGalleryCarousel = !this.state.showGalleryCarousel
  },

  setCommerceStatus(isCommerceInstalled) {
    if (this.debug) console.log('setCommerceStatus triggered with', isCommerceInstalled)
    this.state.isCommerceInstalled = Boolean(isCommerceInstalled)
  },
}

export default store
