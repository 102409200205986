import anime from "animejs"
import Cookies from "js-cookie"


const hotelV = "hotelv";
const theLobby = "thelobby";

const isMobile = () => {
  return window.innerWidth < 960
}

const getDomainName = (hostName) => {
  return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
}

const handleClick = el => {
  if (getDomainName(window.location.hostname).indexOf(hotelV) !== -1) {
    Cookies.set("cookieHotelVAccepted", true, {
      expires: 365,
      domain: "hotelv.com",
      path: '/'
    })
  } else {
    Cookies.set("cookieHotelVAccepted", true, {
      expires: 365,
      domain: "thelobby.nl",
      path: '/'
    })
  }

  let animeOptions = null
  if (isMobile()) {
    animeOptions = {
      targets: el,
      opacity: [1, 0],
      translateX: [0, 32],
      complete: function () {
        el.classList.add("dn")
      },
      duration: 450,
      easing: "easeOutCubic"
    }
  } else {
    animeOptions = {
      targets: el,
      opacity: [1, 0],
      translateX: [0, -32],
      complete: function () {
        el.classList.add("dn")
      },
      duration: 450,
      esing: "easeOutCubic"
    }
  }

  anime(animeOptions)
}

const clickListner = (el, toggleEl) => {
  let animeOptions = null
  if (isMobile()) {
    animeOptions = {
      targets: el,
      opacity: [0, 1],
      translateX: [32, 0],
      duation: 450,
      dlay: 1000,
      easing: "easeOutCubic",
      begin: function () {
        el.classList.remove("dn")
      }
    }
  } else {
    animeOptions = {
      targets: el,
      opacity: [0, 1],
      translateX: [-32, 0],
      duration: 450,
      dlay: 1000,
      easing: "easeOutCubic",
      begin: function () {
        el.classList.remove("dn")
      }
    }
  }

  anime(animeOptions)

  toggleEl.addEventListener("click", () => handleClick(el))
}

const cookieBar = options => {
  const el = document.querySelector(options.el)
  if (!el) return

  const toggleEl = document.querySelector(options.toggle)

  if (Cookies.get("cookieHotelVAccepted") || window.location.hostname.indexOf(theLobby) === -1) return

  clickListner(el, toggleEl)
}

export default cookieBar
