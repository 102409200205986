import anime from "animejs"
import axios from "axios"
import h from "../../helpers"
import s from "../../store"
import "./style.css"

window.contactFormFieldsValidated = []

const handleSuccess = target => {
  const successMessage = document.querySelector(
    ".js-contact-form__message--success"
  )

  anime({
    targets: successMessage,
    opacity: [0, 1],
    begin: () => {
      successMessage.classList.remove("dn")
      successMessage.classList.add("flex")
    },
    duration: 450,
    easing: "easeInOutCubic",
    delay: 150
  })
}

const handleError = target => {
  console.error(`Error submitting form: ${target}`)
}

export const handleContactError = target => {
  const errorMessage = target.querySelector(
    ".js-contact-form__message .js-contact-form__message--error"
  )

  anime({
    targets: errorMessage,
    opacity: [0, 1],
    begin: () => {
      errorMessage.classList.add("flex")
      errorMessage.classList.remove("dn")
    },
    duration: 450,
    easing: "easeInOutCubic",
    delay: 150
  })

  setTimeout(() => {
    anime({
      targets: errorMessage,
      opacity: [1, 0],
      complete: () => {
        errorMessage.classList.remove("flex")
        errorMessage.classList.add("dn")
      },
      duration: 450,
      easing: "easeInOutCubic"
    })
  }, 2000)
}


export const handleContactSubmit = (event, scope) => {
  event.preventDefault()
  const form = event.target
  const data = h.getFormData(form)
  const redirectUrl = form.querySelector("input[name='redirect'") ? form.querySelector("input[name='redirect'").value : ''

  const settings = {
    url: "/",
    data: data,
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      "X-Requested-With": "XMLHttpRequest"
    }
  }

  if (s.debug) console.log("handleContactSubmit", event, scope, settings);

  axios(settings).then(response => {
    if (s.debug) console.log("handleContactSubmit success response:", response);

    if (scope === 'contactForm') {
      !response.data.error
        ? handleSuccess(form)
        : handleError(form)
    } else if (response.data.success && redirectUrl.length) {
      // send success event to GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': scope
      });
      // redirect to success page based on redirectUrl
      window.location = redirectUrl
    } else {
      response.data.success
        ? handleSuccess(form)
        : handleError(form)
    }
  }).catch(function (error) {
    // handle error
    if (s.debug) console.error("handleContactSubmit error", error);
    handleError(form)
  })
}

const ContactFormHandler = options => { }

export default ContactFormHandler
