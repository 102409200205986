import Rellax from "rellax"

const ParallaxRellax = (options) => {
  const els = document.querySelectorAll(options.el)
  if (!els.length) return

  new Rellax(options.el)
}

export default ParallaxRellax
