const ShowFooterMap = (options) => {
  const toggleEl = document.querySelector(options.toggle)
  if (!toggleEl) return

  const targetEl = document.querySelector(options.target)

  toggleEl.addEventListener("mouseover", function () {
    targetEl.style.opacity = '1'
  })
  toggleEl.addEventListener("mouseout", function () {
    targetEl.style.opacity = '0'
  })
}

export default ShowFooterMap
