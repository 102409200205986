import h from "../helpers"

export default {
  install(Vue, options) {
    const getActiveHotelKey = function(sharedState) {
      const activeHotel = sharedState.activeHotel
      if (!activeHotel) return

      const activeHotelKey = h.camelize(activeHotel)
      const hotelCredentials = sharedState.mews[activeHotelKey]
      return hotelCredentials ? hotelCredentials.key : ""
    }

    // Open booking window
    Vue.prototype.handleMewsOpen = function(sharedState) {
      if (sharedState.activeHotel) {
        const key = getActiveHotelKey(sharedState)
        const booking = sharedState.booking

        Mews.Distributor(
          {
            configurationIds: [key]
          },
          api => {
            if (booking.arriveDate) {
              api.setStartDate(booking.arriveDate)
            }

            if (booking.departDate) {
              api.setEndDate(booking.departDate)
            }

            if (booking.adults) {
              api.setAdultCount(booking.adults)
            }

            if (booking.child) {
              api.setChildCount(booking.child)
            }

            if (booking.voucher) {
              api.setVoucherCode(booking.voucher)
            }

            // Show rooms & skip dates if customers already selected in on the site
            if (booking.arriveDate && booking.departDate) {
              api.showRooms()
            }

            api.setLanguageCode(h.langMap(sharedState.siteLang))
            api.open()

            // After book iFrame appears in the DOM, inject hotjar attribute for tracking
            const mewsWindow = document.querySelector("iframe.mews-distributor")
            if (mewsWindow) {
              mewsWindow.setAttribute('data-hj-allow-iframe', "true");
            }
          }
        )
      }
    }
  }
}
